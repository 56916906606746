import React, {useState} from 'react';

import {If, Then, Else} from 'react-if';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Spinner from 'react-bootstrap/Spinner';
import './App.css';

const wsUrl = 'wss://websocket.ericbetts.dev';
const hookUrl = 'https://sockethook.ericbetts.dev/hook';

const ws = new WebSocket(wsUrl);

ws.onopen = event => {
  ws.send(
    JSON.stringify({
      action: 'subscribe',
      key: '2a97516c354b68848cdbd8f54a226a0a55b21ed138e207ad6c5cbb9c00aa5aea', //path: 'demo'
    }),
  );

  setInterval(() => {
    ws.send(
      JSON.stringify({
        action: 'echo',
        value: new Date().getTime(),
      }),
    );
  }, 5 * 60 * 1000);
};

function App() {
  const [messages, setMessages] = useState([]);
  const [subscribed, setSubscribed] = useState(false);

  ws.onmessage = message => {
    const {data} = message;
    try {
      const parsed = JSON.parse(data);
      const {action, state} = parsed;
      // Ignore echo messages
      if (action && action === 'echo') {
        return;
      }
      //Ignore subscribed message
      if (state && state === 'subscribed') {
        setSubscribed(true);
        return;
      }
      setMessages([...messages, parsed]);
    } catch (e) {
      // Not JSON
      setMessages([...messages, data]);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const {target} = event;
    const data = new FormData(target);
    const content = data.get('content');
    await fetch(`${hookUrl}/demo`, {
      method: 'POST',
      mode: 'no-cors',
      body: content,
    });
    target.reset();
  };

  return (
    <>
      <Navbar>
        <Navbar.Brand>Sockethook</Navbar.Brand>
        <Nav>
          <Nav.Link href="https://gitlab.com/bettse/sockethook/">
            Sourcecode (GitLab)
          </Nav.Link>
        </Nav>
      </Navbar>
      <Container className="pb-5">
        <Row>
          <Col>
            <Jumbotron>
              <h1>Sockethook</h1>
              <p> Proxy webhooks to websockets </p>
              <ul>
                <li> Reload page when Netlify deploys new version</li>
                <li>
                  {' '}
                  Update contents when a GitHub PR is opened or new commit is
                  made
                </li>
                <li>
                  {' '}
                  Use command line tools like{' '}
                  <a href="https://httpie.org/">httpie</a> to put live contents
                  onto a site
                </li>
              </ul>
            </Jumbotron>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={6}>
            <Card>
              <Card.Header>Instructions</Card.Header>
              <Card.Body>
                <Card.Text>
                  Choose an arbitrary path, calculate the{' '}
                  <a href="https://emn178.github.io/online-tools/sha256.html">
                    sha256
                  </a>
                  , write down both.
                </Card.Text>
                <h5>On your client</h5>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    Connect to <code>{wsUrl}</code>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Subscribe to the sha of the path by sending the following:{' '}
                    <samp>
                      {JSON.stringify({
                        action: 'subscribe',
                        key: '<sha of path>',
                      })}
                    </samp>{' '}
                    (Using the SHA to prevent disclosing what the path chosen
                    was)
                  </ListGroup.Item>
                  <ListGroup.Item>
                    If you expect messages less than roughtly once every 10
                    minutes, setup an event to send a message over the
                    connection periodically. Whatever is sent (that doesn't
                    match action: subscribe) is echo'd back.
                  </ListGroup.Item>
                </ListGroup>
                <h5>On your server/producer</h5>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    POST to <code>{hookUrl}/&lt;path you chose&gt;</code>. The
                    body will be sent to all subscribed clients.
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card>
              <Card.Header>Demo</Card.Header>
              <Card.Body>
                <If condition={subscribed}>
                  <Then>
                    <Card.Title>Received websocket messages</Card.Title>
                    <Form onSubmit={handleSubmit} inline>
                      <Form.Control name="content" placeholder="hello world" />
                      <Button type="submit">POST</Button>
                    </Form>
                    <ListGroup>
                      {messages.map((message, index) => {
                        return (
                          <ListGroup.Item key={index}>
                            <code>{JSON.stringify(message)}</code>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Then>
                  <Else>
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </Else>
                </If>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <footer className="d-none d-md-block page-footer font-small pt-5 mx-auto">
        <Container fluid className="text-center">
          <Row noGutters>
            <Col>
              <small className="text-muted">
                Inpired by{' '}
                <a href="https://github.com/fabianlindfors/sockethook">
                  fabianlindfors/sockethook
                </a>
              </small>
            </Col>
            <Col>
              <small className="text-muted">
                Built using <a href="https://www.stackery.io/">Stackery</a>
              </small>
            </Col>
            <Col>
              <small className="text-muted">
                Powered by <a href="https://aws.amazon.com/">AWS</a>
              </small>
            </Col>
            <Col>
              <small className="text-muted">
                Hosted with <a href="https://www.netlify.com/">Netlify</a>
              </small>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default App;
